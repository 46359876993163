* {
    padding: 0;
    margin: 0;

}


.team-sec {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.team-sec .team-sec1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.image-sec {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-sec1 img {
    width: 250px;
    height: 300px;
    border: 1px solid #b5b2b5;
    /* box-shadow: 5px 5px 10px; */
}

.dealor-profile {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 20px;
}

.dealor-profile1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 20px;
}

.dealor-profile p, .dealor-profile1 p  {
    margin: 0;
}

.dealor-profile .social-media-sec,
.dealor-profile1 .social-media-sec {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.social-media-sec a {
    display: flex;
    margin: 0 20px 0 0;
    border: 1px solid #931B1F;
    border-radius: 40px;
    padding: 10px;
    color: #931B1F;
    /* box-shadow: gray 5px 5px 20px; */
}

.social-media-sec .facebook:hover {
    color: white;
    background-color: #4267B2;
    border: 1px solid transparent;
}

.social-media-sec .twiter:hover {
    color: white;
    background-color: #00acee;
    border: 1px solid transparent;
}

.social-media-sec .insta:hover {
    color: white;
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    border: 1px solid transparent;
}

.social-media-sec .youtubee:hover {
    color: white;
    background-color: red;
    border: 1px solid transparent;
}
.button-secc{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}
.button-secc .prfile-bottn{
    background-color: white;
    border: 1px solid #931B1F;
    padding: 5px 10px;

}
.button-secc .prfile-bottn:hover{
    background-color:  #931B1F;
    color: white;
    border-radius: 3px;
}
.prfile-bottn:active{
transform: translateY(3px)
}

.team-sec .team-sec2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.team-sec2 img {
    width: 250px;
    height: 300px;
    border: 1px solid #b5b2b5;
}


@media screen and (min-width: 600px) and (max-width: 1024px) {
    .team-sec {
        flex-direction: column;
        margin-top: 30px;
    }

    .team-sec .team-sec1 {
        width: 100%;
        margin-bottom: 20px;
    }

    .team-sec .team-sec2 {
        width: 100%;
        margin-top: 20px;
    }

    .team-sec1 img {
        width: 250px;
        height: 300px;
    }

    .dealor-profile {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 20px;
    }

}


@media screen and (min-width: 480px) and (max-width: 768px) {}

@media screen and (min-width: 320px) and (max-width: 600px) {
    .team-sec {
        flex-direction: column;
        margin-top: 30px;
    }

    .team-sec .team-sec1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }

    .team-sec .team-sec2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .team-sec1 img {
        width: 250px;
        height: 300px;
    }

    .dealor-profile, .dealor-profile1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0 0px;
        padding: 0 0 0 40px;
    }
}