.navbar-nav li a{
    color: #921a1f;
    font-size: 14px;

}
html,
body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

/*Haeder Top*/
.navbar-inverse {
  background-color: #921a1f;
  margin-bottom: 0px;
  min-height: 20px;
  height: 35px;
  border-radius: 0px;
  border: none;
  display: inline-block;
  width: 100%;
}
.modalclose{
  background-color: #921a1f;
}

.social a {
  float: left;
  color: white !important;
}

.contact a {
  color: white !important;
  float: left;
}

.phone a {
  color: white !important;
  float: left;
}
.modalmain{
  position: fixed;
  overflow: hidden;
  z-index: 100;
  height: 200%;
  width: 100%;
  background-color: #000000e6;
}
.modalImagediv{
  margin-left: 30%;
  margin-top: -3px;
}
.modalcrosDivup{
flex-direction: column;
height: 100%;
width: 100%;

}
.modaldiv{
  margin-left: 62%;
  z-index: 1;
}
.modalcross{
  background-color: #000000e6;
  margin-top: 5px;
  color: white;
  width: 10px;
  height: 10px;
}
.navbar-right>li>a {
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .nav>li>a {
    padding-top: 3px;
  }
}

@media screen and (max-width: 500px) {
  .social a {
    padding: 5px !important;
  }

  .contact a {
    padding: 5px !important;
    margin-left: 12px !important;
  }

  .phone a {
    padding: 5px !important;
  }


}

/*Header*/
.nav li {
  list-style: none;
}

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  /* background-color: #ffffff; */
}

.navbar-default {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #ffffff;
  position: relative;
  min-height: 60px !important;
  margin-bottom: 0px;
  border: none;
}

.navbar-default .navbar-nav>.open>a {
  background-color: white !important;
}

.navbar-brand {
  float: none;
}

.navbar-toggle {
  margin-top: 50px;
}

#main-nav {
  margin: 30px 25px;
}

#main-nav li a {
  color: #921a1f;
  padding: 10px;
}




.flash-button {
  background-color: red;
  -webkit-border-radius: 60px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
}

@keyframes glowing {
  0% {
    background-color: red;
    box-shadow: 0 0 5px red;
    color: white;

  }

  50% {
    background-color: red;
    box-shadow: 0 0 20px red;
    color: white;

  }

  100% {
    background-color: red;
    box-shadow: 0 0 5px red;
    color: white;

  }
}

.flash-button {
  animation: glowing 1000ms infinite;
}


/*#LiNavbar .dropdown
{
  position: relative;
}
#LiNavbar .dropdown-menu 
{
  display: none;
  position: absolute;
}
#LiNavbar .dropdown:hover .dropdown-menu 
{
  display: block;

}

#sub-dropdown-menu .dropdown-menu
{
  display: none;
  position: absolute;
}
#sub-dropdown:hover #sub-dropdown-menu
{
  display: block;
}*/
#fooot{
  display: none;
}
.Ccarousel{
  height:550px;
}
.img1{
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.drop-menu ul lu {
  display: inline-block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.22857143;
  position: relative;
  white-space: normal;
}

.navbar-collapse.in {
  overflow-y: inherit;
}

/*Welcome Note*/
h5 {
  color: #921a1f;
  font: 500 16px/1.2em commercial black;
}
.welcome{
  font-size: 17px;
  color: #000000;
  text-align: justify;
  font-family: Kreon;
  font-style: normal
}

/*Property slider*/
.PC {
  position: absolute;
  top: -40px;
  bottom: 382px;
  left: 970px;
  width: 10%;
  font-size: 20px;
  color: #921a1f;
  text-align: center;
  filter: alpha(opacity=20);
  opacity: 2.5;
  background-image: none !important;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .PC {
    position: absolute !important;
    top: -40px !important;
    bottom: 382px !important;
    left: 785px;
    width: 10% !important;
    font-size: 20px !important;
    color: #921a1f !important;
    text-align: center !important;
    filter: alpha(opacity=20) !important;
    opacity: 2.5 !important;
    background-image: none !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .PC {
    position: absolute !important;
    top: -40px !important;
    bottom: 382px !important;
    left: 590px;
    width: 10% !important;
    font-size: 20px !important;
    color: #921a1f !important;
    text-align: center !important;
    filter: alpha(opacity=20) !important;
    opacity: 2.5 !important;
    background-image: none !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .PC {
    position: absolute !important;
    top: -40px !important;
    bottom: 382px !important;
    left: 0px;
    width: 45% !important;
    font-size: 20px !important;
    color: #921a1f !important;
    text-align: center !important;
    filter: alpha(opacity=20) !important;
    opacity: 2.5 !important;
    background-image: none !important;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .PC {
    position: absolute !important;
    top: -40px !important;
    bottom: 382px !important;
    left: 128px;
    width: 46% !important;
    font-size: 10px !important;
    color: #921a1f !important;
    text-align: center !important;
    filter: alpha(opacity=20) !important;
    opacity: 2.5 !important;
    background-image: none !important;
  }
}

.carousel-control:focus,
.carousel-control:hover {
  color: #921a1f;
}

#img {
  width: 100%;
}

.BCP {
  padding-left: 1px !important;
  padding-right: 0px !important;
}

/* .bottom-left {
  position: absolute;
  bottom: 40px;
  left: 30px;
  color: white;
} */

.carousel-indicators li {
  position: relative;
  top: 40px;
  background-color: #959595
}

.carousel-indicators .active {
  position: relative;
  top: 40px;
  background-color: #959595
}

.fa-camera {
  position: relative;
  left: 500px;
  bottom: 30px;
  color: black;
}

/*Ameneties*/
#Am h5 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
}

.image {
  width: 370px;
  height: 260px;
  padding-bottom: 30px;
}

.vc_btn3 a {
  background-color: #165359;
  color: white;
  padding: 3%;
  text-decoration: none;
}
.vc_btn3 a:hover{
  color: white;
}


.overlay {
  
  position: absolute;
  bottom: 10%;
  left: 0px;
  right: 0;
  background-color: #5aa9b1;
  overflow: hidden;
  width: 100%;
transform: scale(1.1);
  z-index: 9;
  transition: .5s ease;
}

.over {
  padding-left: 0px;
}


.over:hover .overlay {
  height: 90%;
  
}

/*.vc-custom{
  color: #ffffff;
  text-align: center;
  font-style: bold;
}*/
.text h2 {
  color: #ffffff;
  text-align: center;
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}

.color {
  color: black;
  margin: 0 0 50px 0;
}

.text {
  color: white;
  font-size: 14px;
  position: absolute;
  padding: 20px;
  text-align: center;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .image {
    width: 305px;
    height: 260px;
    padding-bottom: 30px;
  }

  .over:hover .overlay {
    height: 90;
    width: 307px;
  }

  .text {
    color: white;
    font-size: 14px;
    position: absolute;
    padding: 5px;
    text-align: center;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .image {
    width: 350px;
    height: 260px;
    padding-bottom: 30px;
  }

  .over:hover .overlay {
    height: 90;
    width: 350px;
  }

  .text {
    color: white;
    font-size: 14px;
    position: absolute;
    padding: 15px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 601px) {
  .image {
    width: 100%;

    padding-bottom: 30px;
  }

  .over:hover .overlay {
    height: 90;
    width: 98%;
  }

  .text {
    color: white;
    font-size: 15px;
    position: absolute;
    padding: 20px;
    text-align: center;
  }

  .swimtext {
    margin-left: 200px !important;
  }

  .gymtext {
    margin-left: 200px !important;
  }

  .squashtext {
    margin-left: 200px !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
  .image {
    width: 100%;
    padding-bottom: 30px;
  }

  .over:hover .overlay {
    height: 90;
    width: 98%;
  }

  .text {
    color: white;
    font-size: 15px;
    position: absolute;
    padding: 20px;
    text-align: center;
  }

  .swimtext {
    margin-left: 100px !important;
  }

  .gymtext {
    margin-left: 100px !important;
  }

  .squashtext {
    margin-left: 100px !important;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .image {
    width: 100%;
    padding-bottom: 30px;
    margin: 0px !important;
  }

  .over:hover .overlay {
    height: 90;
    width: 98%;
  }

  .text {
    color: white;
    font-size: 14px;
    position: absolute;
    padding: 20px;
    text-align: center;
  }

  .swimtext {
    margin-left: 35px !important;
  }

  .gymtext {
    margin-left: 50px !important;
    padding: 30px;
  }

  .squashtext {
    margin-left: 50px !important;
  }
}

/*About-US AND CEO*/
.aboutus-and-ceo {
  margin-top: 50px;
}

.aboutus-and-ceo img {
  width: 100%;
}

/*About US Other Businesses section*/
#BS h5 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
  font: 500 30px/1.2em commercial black;
}

.Aboutimage {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}

.vcbtn3 a {
  background-color: #165359;
  color: white;
  padding: 2%;
}

.Aboutoverlay {

  bottom: 10%;
  left: 0px;
  right: 0;
  background-color: #5aa9b1;
  overflow: hidden;
  width: 93%;
  height: 50px;
  transition: .5s ease;
}

.Aboutimage:hover .Aboutoverlay {
  height: 90%;
}

.Abouttext {
  color: black;
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .Abouttext {
    color: white;
    font-size: 14px;
    text-align: center;
  }

  .Abouttext h2 {
    color: white;
    font-size: 19px;
    text-align: center;
    font-weight: bold;
  }

  .over:hover .Aboutoverlay {
    height: 90%;
    width: 97%;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .smMar {
    margin-left: 100px;
  }

  .Abouttext {
    color: white;
    font-size: 12px;
    text-align: center;
  }

  .Abouttext h2 {
    color: white;
    font-size: 19px;
    text-align: center;
    font-weight: bold;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .smMar {
    margin-left: 70px;
  }

  .Abouttext {
    color: white;
    font-size: 10px;
    text-align: center;
  }


  .Abouttext h2 {
    color: white;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }

  .CSRtext {
    color: white;
    font-size: 12px !important;
    position: relative;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

/*About_US CSR*/
#CSR h5 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
  font-size: 30px;
}

#CSRimg {
  width: 100%;
}

.vcbtn3 a {
  background-color: #165359;
  color: white;
  padding: 2%;
}

.oo {
  position: absolute;
  bottom: 0%;
  left: 0px;
  right: 0;
  background-color: #5aa9b1;
  overflow: hidden;
  width: 75%;
  height: 0;
  transition: .5s ease;
}

.csr:hover .overlay {
  height: 100%;
}

.CSRtext {
  color: white;
  font-size: 14px;
  position: relative;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/*Features Section*/
.block-content h5 {
  text-align: center;
  color:rgb(16, 15, 15);
  font-family: Arial, Helvetica, sans-serif ;
  
}
.tools{
  z-index: 1;
}



.block-icon img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;
}
#modalcontent{
  height: 620px;
  margin-top: -120px;
  background-color: rgb(0, 0, 0);
}

.webopen{
  width:450px;
  height: 610px;
  margin-top: -15px;
}


.service-block .block-icon {
  font-size: 60px;
  min-width: 120px;
  padding: 80px 0 70px;
}

#features h5 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
  font-size: 25px;
}
#features h1 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
}

.service-block:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}

/*Layout Section*/
#la-img {
  width: 100%;
  height: 100%;
}
.MC4 h4 {
  font-size: 50px;
  color: #921a1f;
  text-align: center;
}
.Modal {
  position: absolute;
  height: 170px;
  background-color:#921a1f;
  border-radius: 10px;
  text-align: center;
  border: none;
  box-shadow:10px 10px 15px  #ea3939,-5px -5px 15px  #ea3939,30px 30px 25px  #de9b2e,-20px -20px 25px  #de9b2e ;
  
  
}
.Modal h2{
  color:white;
  line-height: 150px;


}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:none;
}
.layout-section2 h2 {
  font-size: 20px;
  color: #165359;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal
}

.layout-section2 p {
  font-size: 17px;
  color: #000000;
  text-align: justify;
  font-family: Kreon;
  font-style: normal
}
.Press{
  height: 550px;
  background-color:white;
  box-shadow:5px 5px 10px gray,-3px -5px 10px gray;
}
.pressbody{
  background-image: url("../../public/12.jpg");
  background-size: cover;
}
.PRimgs{
  width:550px;
}
#presscard{
  width:90%;
}
#layout h5 {
  color: #921a1f;
  text-align: center;
  padding-bottom: 40px;
}

.embed iframe {
  width: 500px;
  height: 275px;
}
.residentialimg{
  width:1000px;
}
@media screen and (max-width:2400px) and (min-width:1200px) {
  .embed iframe{
    width: 100%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  #la-img {
    width: 100%;
    height: 300px;
  }
  .residentialimg{
    width:700px;
  }

  .embed iframe {
    width: 100%;
    height: 275px;
  }
}


@media screen and (max-width: 767px) and (min-width: 500px) {
  #la-img {
    width: 100%;
    height: 300px;
  }
  .PRimgs{
    width:280px;
  }
  .news-p{
    text-align: justify;
    width:280px;
  }
  .residentialimg{
    width:450px;
  }

  .embed iframe {
    width: 100%;
    height: 250px;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  #la-img {
    width: 100%;
    height: 300px;
  }
  .residentialimg{
    width:300px;
  }
  .presslogo{
    display: none;
  }
  .PRimgs{
    width:280px;
  }
  .news-p{
    text-align: justify;
    width:280px;
  }
  .Modal{
    height:100px;
  }
  .Modal h2{
    line-height: 90px;
  }
  .MC4 h4{
    font-size:20px;
  }

  .embed iframe {
    width: 100%;
    height: 250px;
  }
}

/*Up Button*/
.scrolltop-btn {
  display: inline-block;
  color: #fff;
  background-color: #921a1f;
  border-color: #921a1f;
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px !important;
  z-index: 999;
  transition: none;
  border-radius: 4px;
}

/*map*/
.map{
  width:100%;
}
.map-container {
  overflow: hidden;
  padding-bottom: 31.25%;
  position: relative;
  margin-top: 50px;
}

.map-container iframe {
  position: absolute;
  left: 85px;
  top: 0;
  height: 85%;
  width: 85%;
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  
  .modalImagediv{
    margin-left:15%;
    }
    
      .modaldiv{
        /* margin-right: 2%; */
        float: right;
        margin-left: 75%;
      }
      .modalcrosDivup{
        margin: 5px;
      }.map-container {
    overflow: hidden;
    padding-bottom: 61.25%;
    position: relative;
    margin-top: 50px;
  }

  .map-container iframe {
    position: absolute;
    left: 65px;
    top: 0;
    height: 85%;
    width: 75%;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .map-container {
    overflow: hidden;
    padding-bottom: 61.25%;
    position: relative;
    margin-top: 50px;
  }
.modalImagediv{
margin-left:10%;
}

  .modaldiv{
    /* margin-right: 2%; */
    float: right;
    margin-left: 85%;
  }
  .modalcrosDivup{
    margin: 5px;
  }
  .webopen{
    width:280px;
    height: 400px;
  }
  #modalcontent{
    width: 320px;
    height: 405px;
    margin-top: -50px;
  }
 

  .map-container iframe {
    position: absolute;
    left: 0px;
    top: 0;
    height: 85%;
    width: 100%;
  }
}

/*Dealers Section*/
#agents h5 {
  color: #921a1f;
  text-align: center;
  padding: 40px;
}

.block-content a {
  margin-left: 39%;
  color: #921a1f;
}
.block-content p{
  font-size: 17px;
  color: #000000;
  text-align: justify;
  font-family: Kreon;
  font-style: normal
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .block-content a {
    margin-left: 42%;
  }
}

.service-block p {
  font-size: 17px;
  color: #000000;
  text-align: justify;
  font-family: Kreon;
  font-weight: 300;
  font-style: normal
}

.block-content h3 {
  text-align: center;
}

.block-content p {
  text-align: justify;
}

.block-icon img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.service-block:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}

/*Footer bottom*/
.navbar-bottom {
  background-color: #921a1f;
  border-top: 1px solid #00243f;
  font-size: 14px;
  color: hsl(0, 0%, 100%);
  border-radius: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  

}

#footer-menu a:focus,
#footer-menu a:hover {
  text-decoration: none;
}

#footer-menu a {
  float: left;
  color: white;
  padding-right: 30px;
  text-decoration: none;

}

#footer-menu {
  list-style: none;
  
}

.footer-col p {
  padding-top: 10px;
  margin: 0px;
  text-align: center;
  text-decoration: none;

}

@media screen and (max-width: 560px) and (min-width: 300px) {
  #footer-menu a {
    padding-right: 50px;
  }
}

/*Footer Top*/
.footer-t {
  background-color: #165359;
  color: white;
  padding: 80px;
  
}

#form-control {
  padding: 9px;
  border-radius: 4px;
  border: none;
  color: black;
  width: 70% !important;
}

.textwidget a {
  color: white;
  text-decoration: none;
  
}

.textwidget a:focus,
.textwidget a:hover {
  text-decoration: none;
  color: white;

}

.social li {
  float: left;
  color: white;
  list-style: none;
  padding-left: 10px;
}

#btn {
  padding: 10px 30px 10px 30px;
  background-color: #921a1f;
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  #btn {
    padding: 5px 20px 5px 20px;
    background-color: #921a1f;
  }

  #form-control {
    padding: 6px;
    border-radius: 4px;
    border: none;
    color: black;
    width: 100% !important;
  }

  .social li {
    float: left;
    color: white;
    list-style: none;
    padding-left: 2px !important;
  }
}

/*Social Media buttons on side*/
#icon_wrapper {
  position: fixed;
  top: 25%;
  left: 0px;
  z-index: 99999;
}
#icon_wrapp {
  position: fixed;
  top: 25%;
  right: 10px;
  z-index: 99999;
}
#NewsBtn {
  position: fixed;
  top: 25%;
  right: 1px;
  z-index: 99999;

  
}

#NewsCard{
  width:300px;
  height:350px;
  background-color: white;
  box-shadow:5px 5px 10px  #565353, -5px -5px 10px  #565353 ;
  border-radius: 10px;
  display: none;
}
.news-p{
  text-align: justify;
  
}
#newsBtn{
  writing-mode: vertical-rl;
  color:white;
  padding: 1px;
  text-align: center;
  background-color:#921a1f;
  border-radius: 5px;
  cursor: pointer;
}
.Divider{
  width:70%;
  border: 2px solid black;
  background: #000000;
}


.fuse_social_icons_links {
  outline: 0 !important;
}

.fb-awesome-social {
  background: #3b5998;
}

.tw-awesome-social {
  background: #00aced;
}

.linkedin-awesome-social {
  background: #007bb6;
}

.youtube-awesome-social {
  background: #bb0000;
}

.instagram-awesome-social {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.envelope-awesome-social {
  background: #ccc;
}

.awesome-social {
  margin-top: 2px;
  color: white !important;
  text-align: center !important;
  line-height: 34px !important;
  width: 32px !important;
  height: 32px !important;
  font-size: 1.5em !important;
  text-shadow: 2px 2px 4px #000000;
  transition: width 0.7s, height 0.7s, transform 0.7s;
}

/*Contact-US Form*/
.form-control {
  height: 50px;
}

.module-Item {
  width: 105%;

}

.head-of-marketing {
  height: 414px;
  width: 290px;
}

/*responsive head of marketing pic*/
@media screen and (max-width: 500px) {
  .module-Item {
    width: 80%;
  }


  .service-block .block-icon {
    font-size: 60px;
    min-width: 282px;
    padding: 10px 0 10px;
  }

  #agents h5 {
    color: #921a1f;
    font: 487 20px/1.2em commercial black;
  }

  .module-item,
  .col-sm-3 {
    padding-left: 23px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 23px;

  }
}

/*Brochure*/
.btn-danger {
  color: #fff;
  background-color: #921a1f;
  border-color: #d43f3a;
  padding: 10px;
  padding-right: 85px;
  padding-left: 85px;
  margin-top: 10px;
  margin-left: 10px;
}

.pic {
  width: 100%;
}

.picture {
  background-color: #f0eeee;
  padding: 30px 15px 30px 15px;
}

.picture a {
  margin-left: 35%;
  color: black;
}

.pic,
h3,
a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .btn-danger {
    color: #fff;
    background-color: #921a1f;
    border-color: #d43f3a;
    padding: 10px;
    padding-right: 65px;
    padding-left: 65px;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .btn-danger {
    color: #fff;
    background-color: #921a1f;
    border-color: #d43f3a;
    padding: 10px;
    padding-right: 45px;
    padding-left: 45px;
    margin-top: 10px;
    margin-left: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .btn-danger {
    color: #fff;
    background-color: #921a1f;
    border-color: #d43f3a;
    padding: 4px;
    padding-right: 25%;
    padding-left: 25%;
    margin-top: 10px;
    margin-left: 65px;
  }

  .pic {
    width: 70%;
    margin-left: 70px;
  }

  .picture {
    background-color: #f0eeee;
    padding: 30px 80px 30px 80px;
  }

  .picture a {
    margin-left: 43%;
    color: black;
  }
}

/*Commercial*/


.ic4 {
  background-color: #f0eeee;
  margin-top: 20px;
  border: 1px solid #ada9a5;
  padding: 40px 10px 40px 10px;
}

.bc4 {
  background-color: #f0eeee;
  margin-top: 0px;
  border: 1px solid #ada9a5;
}
#b4 a{
  color: white;
  text-decoration: none;
}

.back-button {
  margin-top: 40px;
  margin-left: 5px;
}

.back-button a {
  padding: 15px 200px 15px 200px;
  background-color: #921a1f;
  color: #ffffff;
  border-radius: 4px;
}

.contact-button {
  margin-top: 40px;
  margin-left: 5px;
}

.contact-button a {
  padding: 15px 200px 15px 200px;
  background-color: #921a1f;
  color: #ffffff;
  border-radius: 4px;
}

#b4 {
  padding: 15px 25px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  float: left;
}

.bb4 {
  background-color: #f0eeee;
  border: 1px solid #ada9a5;
  padding: 20px;
  
}

.ic4 img {
  width: 100%;
  margin-bottom: 5px;
}
@media screen and (max-width:1600px) and (min-width:1200px) {
  #fTable{
    margin-top: -150px;
  }
}
@media screen and (max-width:992px) and (min-width:700px) {
  #sTable{
    margin-top: -80px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .back-button a {
    padding: 15px 100px 15px 100px;
  }
  

  .contact-button a {
    padding: 15px 100px 15px 100px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .back-button a {
    padding: 15px 75px 15px 75px;
  }

  .contact-button a {
    padding: 15px 75px 15px 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .back-button a {
    padding: 15px 30px 15px 30px;
  }

  .contact-button a {
    padding: 15px 30px 15px 30px;
  }
}

/*TVC*/

/*Events Section*/
#E1 {
  text-align: center;
  color: #921a1f;
  padding: 20px;
}

.EIC {
  border: 5px solid #d4af37;
  padding: 30px 0px 80px 0px;
}

#EventRC {
  right: 0px !important;
}

.EventSh h1 {
  color: #921a1f;
  text-align: center;
  font-size: 25px;
}

.EventSh h4 {
  color: #921a1f;
  text-align: center;
}

.EventLis {
  color: #0099FF;
  text-align: left;
  font-size: 16px;
}

.E5CCL {
  position: absolute;
  left: 15px;
}

.E5CCR {
  position: absolute;
  right: 16px !important;
}

@media screen and (max-width: 991px) and (min-width: 768px) {}

/*Dealers*/
#Dagents h5 {
  background-color: #696969;
  color: white;
  text-align: center;
}

.DagentC {
  margin-top: 50px;
}

/*residential payment plan*/
.cardcontainer {
  background-color: #f0eeee;
  padding: 50px 30px 50px 30px;
}

.card-img-top {
  position: relative;
  top: 50px;
  left: 50px;
  width: 60%;
}


.cardcol {
  padding-right: 0px;
  padding-left: 0px;
}

.card {
  height: 350px;
  background-color: white;
  border: #f0eeee solid .1px;
}

.card-body {
  text-align: center;
  margin-top: 80px;
}

.card:hover {
  /* -ms-transform: scale(1.1); */
  /* IE 9 */
  /* -webkit-transform: scale(1.1); */
  /* Safari 3-8 */
  /* transform: scale(1.1); */
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .card-img-top {
    position: relative;
    top: 50px;
    left: 110px;
    width: 40%;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .card-img-top {
    position: relative;
    top: 50px;
    left: 110px;
    width: 40%;
  }
}

@media screen and (max-width: 759px) and (min-width: 300px) {
  .topbar{
    display:none;
  }
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    position: relative;
    top: 50px;
    left: 40px;
    width: 60%;
  }
  .ViewProfilee{
    font-size: 11px;
  }
  #icon_wrapper{
    display: none;
  }
 


  .card {
    height: 300px;
  }
}


/*commercial-payment-plan*/
.MCPP p {
  font: 700 33px/1.2em Montserrat;
  color: #000000;
  text-align: center;
}

.CPT {
  border: 2px solid #165359;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  width: 100%;
}

#TRH {
  font-weight: bold;
  border: 2px solid #fff;
  text-align: center;
  color: white;
  background-color: #921a1f;
  font-size: 18px;
}

#TCH {
  font-weight: bold;
  border: 2px solid #165359;
  text-align: center;
  color: #fff;
  background-color: #d1b56c;
  font-size: 18px;
}

#TB {
  border: 2px solid #165359;
  text-align: center;
  color: #264a5d;
  background-color: white;
  font-weight: bold;
  font-size: 17px;
}

.LCTP p {
  font: 700 25px/1.2em Montserrat;
  color: #921a1f;
  text-align: left;
}

.LCTP h5 {
  font-size: 20px;
  color: #921a1f;
}

.TCC {
  border: #921a1f solid .1px;
}

#TR23 {
  border: 2px solid #165359;
  text-align: center;
  color: #a6a6a6;
}

#TR1 {
  border: 2px solid #165359;
  text-align: center;
  color: #264a5d;
  height: 54px;
}

@media screen and (max-width: 580px) and (min-width: 300px) {

  #TRH {
    font-size: 16px;
  }

  #TCH {
    font-size: 12px;
  }

  #TB {
    font-size: 12px;
  }
}

/*By Laws section*/
.ByLawH3 {
  font-size: 30px;
  color: #921a1f;
  text-align: center;
}

.ByLawP1 {
  font-size: 30px;
  color: #165359;
  text-align: left;
}

.ByLawP2 {
  font-size: 20px;
  color: #000000;
  text-align: center;
}

#regulations li {
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
}

.LC1 {
  margin-top: 50px;
  background-color: #e5e5e5;
  border: #e5e5e5 solid .1px;
}

.LC2 {
  border: #e5e5e5 solid .1px;
  padding: 50px 10px 50px 10px;
}

.LC3 {
  border: #e5e5e5 solid .1px;
  margin-top: 50px;
}

.LC3 h3 {
  font: 35px/1.2em Montserrat;
  color: #921a1f;
  text-align: left;
}

.LC3 p {
  text-align: center;
  font-size: 18px;
}

.LC3 table {
  border: 2px solid #165359;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  width: 900px;
  height: 350px;
}

.LC3 tr {
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  ;
  height: 12px;
}

.LC3 th {
  font-weight: normal;
  border: 2px solid #921a1f;
  color: #fff;
  background-color: #921a1f;
  font-size: 19px;
  text-align: center;
}

.LC3 td {
  font-weight: normal;
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  background-color: #fff;
  font-size: 17px;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .LC3 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 700px;
    height: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .LC3 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 450px;
    height: 200px;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .LC3 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 330px;
    height: 200px;
  }
  .ByLawH3{
  font-size: 20px;
}
.ByLawP1{
  font-size: 20px;
}
}

.LC4 {
  border: #e5e5e5 solid .1px;
  margin-top: 50px;
}

#lc4h3-1 {
  font: 35px/1.2em Montserrat;
  color: #921a1f;
  text-align: left;
}

#lc4h3-2 {
  font: 30px/1.2em Montserrat;
  color: #921a1f;
  text-align: center;
}

#lc4h3-3 {
  font: 30px/1.2em Montserrat;
  color: #921a1f;
  text-align: center;
}

#lc4h3-4 {
  font: 30px/1.2em Montserrat;
  color: #921a1f;
  text-align: center;
}

.LC4 tr {
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  height: 12px;
}

.LC4 th {
  font-weight: normal;
  border: 2px solid #921a1f;
  color: #fff;
  background-color: #921a1f;
  font-size: 19px;
  text-align: center;
}

.LC4 td {
  font-weight: normal;
  border: 2px solid #921a1f;
  color: #921a1f;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
}

.LC4 p {
  text-align: center;
  font-size: 17px;
}

.LC4 table {
  border: 2px solid #165359;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  width: 900px;
  height: 228px;
}

.LC4T2 {
  border: 2px solid #165359;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  width: 901px;
  height: 124px;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .LC4 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 700px;
    height: 228px;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .LC4 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 450px;
    height: 228px;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .LC4 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 330px;
    height: 200px;
  }
}

.LC5 {
  border: #e5e5e5 solid .1px;
  margin-top: 50px;
}

.LC5 h3 {
  font: 35px/1.2em Montserrat;
  color: #921a1f;
  text-align: left;
}

.LC5 table {
  border: 2px solid #165359;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  width: 906px;
  height: 215px;
}

.LC5 tr {
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  height: 12px;
}

.LC5 td {
  font-weight: normal;
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  background-color: #fff;
  font-size: 17px;
}

.LC5 th {
  font-weight: normal;
  border: 2px solid #921a1f;
  color: #fff;
  background-color: #921a1f;
  font-size: 19px;
  text-align: center;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .LC5 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 706px;
    height: 215px;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .LC5 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 456px;
    height: 215px;
  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .LC5 table {
    border: 2px solid #165359;
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    width: 330px;
    height: 200px;
  }
  
}

.LC6 {
  border: #e5e5e5 solid .1px;
  margin-top: 50px;
  font-size: 17px;
}

.LC6 h3 {
  font: 35px/1.2em Montserrat;
  color: #921a1f;
  text-align: left;
}

.DS {
  border: #e5e5e5 solid .1px;
  margin-top: 50px;
  color: #921a1f;
  text-align: center;
  font-size: 40px;
  padding: 20px 10px 20px 10px;
}

.DSS {
  border: #e5e5e5 solid .1px;
  padding: 40px 0px 40px 0px;
}

.DSCC {
  width: 0%;
  color: #0c0c0c;
}

/*Amenities Page*/
.AMPH h3 {
  font: 35px/1.2em Montserrat;
  text-align: center;
  color: #921a1f;
}

.AMPH p {
  font: 25px/1.2em Montserrat;
  text-align: center;
  color: #165359;
}

.amenities-btn {
  margin-left: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #165359;
}

.amenities-btn a {
  color: #ffffff;
}

.amenities-btn:hover {
  background-color: #165359;
}

.AMC {
  border: 2px solid #d4af37;
  padding: 30px 0px 80px 0px;
}

.AMcardcontainer {
  margin-top: 50px;
  background-color: #f0eeee;
  padding: 50px 30px 50px 30px;
  margin-bottom: 50px;
}

.AMcard-img-top {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 35%;
}

.AMcardcol {
  padding-right: 0px;
  padding-left: 0px;
}

.AMcard {
  height: 500px;
  background-color: white;
  border: #f0eeee solid .1px;
}

.AMcard-body {
  text-align: center;
  margin-top: 100px;
  font-size: 17px;
}

.AMcard:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}

.AMcard-text {
  padding: 10px 50px 10px 50px;
}

@media screen and (max-width: 1200px) and (min-width: 990px) {
  .amenities-btn {
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
  }
  .card{
    width:280px;
  }

  .AMcard {
    height: 600px;
    background-color: white;
    border: #f0eeee solid .1px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .amenities-btn {
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
  }
 
  .AMcard-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
  }
}

@media screen and (max-width: 767px) and (min-width: 500px) {
  .amenities-btn {
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
  }

  .AMcard-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;

  }
}

@media screen and (max-width: 499px) and (min-width: 300px) {
  .amenities-btn {
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
  }
  .card{
    width:100%;
  }
  .AMcard-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    width: 25%;
  }
}

/*Mosque*/
#AMSB {
  font-size: 15px;
  line-height: 35px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}


#GotoAmB {
  background-color: #333;
border-radius: 5px;

}
#GotoAmB:hover{
  background-color: white;
  border:1px solid gray;
}

#AMSB:hover {
  text-decoration: none;
  color:black;
  
}

#AMh6 {
  text-align: center;
  font-size: 17px;
}

.descriptionbtn {
  width: 330px;
  padding: 10px;
  background-color: orange;

}

.featuresbtn {
  width: 300px;
  padding: 10px;
  background-color: orange;


}


#AMcolapsp {
  font-size: 17px;
  text-align: justify;
}

.ulcolapse {
  list-style: none;
}

.ulcolapse li {
  font-size: 15px;
}

.ulcolapse a {
  color: black;
}

.ulcolapse a:hover {
  color: rgba(157, 6, 5, 0.75);
  text-decoration: none;
}

#AMCamera {
  font-size: 30px;
}

.cameraicon {
  position: relative;
  left: 150px;
  top: 100px;
}

.ULCamera {
  list-style: none;
}

@media screen and (max-width: 768px) and (min-width: 561px) {
  #AMCamera {
    font-size: 40px;
  }

  .cameraicon {
    position: relative;
    left: 230px;
    top: 50px;
  }
 
}

@media screen and (max-width: 560px) and (min-width: 300px) {
  #AMCamera {
    font-size: 40px;
  }
  .Ccarousel{
    height:250px;
  }
  #Ccard{
    width:340px;
  }
 

  .cameraicon {
    position: relative;
    left: 110px;
    top: 50px;
  }
}

/*All Payment Plan Pages*/
.FiveMarlaPP p {
  font: 700 33px/1.2em Montserrat;
  color: #000000;
  text-align: center;
}

.FiveMarlaPPOH p {
  font: 700 20px/1.2em Montserrat;
  color: #165359;
  text-align: center;
  
}


.FMFTable {
  border: 1px solid black;
}

.FiveMarlaPPTable {
  border: 2px solid #165359;
  text-align: center;
  width: 90%;
  height: 100%;
}

#FiveMarlaTr1 {
  border: 2px solid #165359;
  text-align: center;
  color: #a6a6a6;
  height: 30px;
}

.FiveMarlaPPTable tr {
  border: 2px solid #165359;
  text-align: center;
  color: #a6a6a6;
  height: 12px;
}

.FiveMarlaPPTable th {
  font-weight: normal;
  border: 2px solid #165359;
  color: #ffffff;
  background-color: #165359;
  font-size: 20px;
  text-align: center;
}

.FiveMarlaPPTable td {
  font-weight: normal;
  border: 2px solid #165359;
  color: #165359;
  background-color: white;
  font-size: 20px;
  text-align: center;
}

.FiveMarlaPPTable2 {
  border: 2px solid #165359;
  text-align: center;
  width: 90%;
  height: 100%;
}

#FiveMarlaTr1 {
  border: 2px solid #165359;
  text-align: center;
  color: #a6a6a6;
  height: 30px;
}

.FiveMarlaPPTable2 tr {
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  height: 12px;
}

.FiveMarlaPPTable2 th {
  font-weight: normal;
  border: 2px solid #921a1f;
  color: #fff;
  background-color: #921a1f;
  font-size: 19px;
  text-align: center;
}

.FiveMarlaPPTable2 td {
  font-weight: normal;
  border: 2px solid #921a1f;
  text-align: center;
  color: #921a1f;
  background-color: #fff;
  font-size: 17px;
}

.FiveMarlaPPNotes p {
  font-family: "Times New Roman", "Times";
  font-size: 30px;
  font-weight: bold;
  color: #921a1f;
}

.FiveMarlaPPNotes h5 {
  font-family: "Times New Roman", "Times";
  font-size: 17px;
  color: #921a1f;
}

#FiveMarlaPPBtn {
  width: 100%;
  padding: 15px;
  background-color: #165359;
  margin-top: 5px;
  border-color: #165359;
}

.FiveMarlaPPBtn:hover {
  background-color: #165359;
}

.FiveMarlaPPBtn a {
  color: white;
}

.FiveMarlaPPBtn a:hover {
  text-decoration: none;
}

#img:hover {
  cursor: pointer;
}

.anim-img img {
  width: 100%;
}

.com_img{
  height: 275px;
}

#Marketing{
  height: 400px;
}

/* Dealer Section CSS */

* {
  padding: 0;
  margin: 0;

}



.team-sec {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.team-sec .team-sec1 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-sec {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-sec1 img {
  width: 250px;
  height: 300px;
  border: 1px solid #b5b2b5;
  /* box-shadow: 5px 5px 10px; */
}

.dealor-profile {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 20px;
}

.dealor-profile1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 20px;
}

.dealor-profile p, .dealor-profile1 p  {
  margin: 0;
}

.dealor-profile .social-media-sec,
.dealor-profile1 .social-media-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-media-sec a {
  display: flex;
  margin: 0 20px 0 0;
  border: 1px solid #931B1F;
  border-radius: 40px;
  padding: 10px;
  color: #931B1F;
  /* box-shadow: gray 5px 5px 20px; */
}

.social-media-sec .facebook:hover {
  color: white;
  background-color: #4267B2;
  border: 1px solid transparent;
}

.social-media-sec .twiter:hover {
  color: white;
  background-color: #00acee;
  border: 1px solid transparent;
}

.social-media-sec .insta:hover {
  color: white;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  border: 1px solid transparent;
}

.social-media-sec .youtubee:hover {
  color: white;
  background-color: red;
  border: 1px solid transparent;
}
.button-secc{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.button-secc .prfile-bottn{
  background-color: white;
  border: 1px solid #931B1F;
  padding: 5px 10px;

}
.button-secc .prfile-bottn:hover{
  background-color:  #931B1F;
  color: white;
  border-radius: 3px;
}
.prfile-bottn:active{
transform: translateY(3px)
}

.team-sec .team-sec2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-sec2 img {
  width: 250px;
  height: 300px;
  border: 1px solid #b5b2b5;
}



@media screen and (min-width: 600px) and (max-width: 1024px) {
  .team-sec {
      flex-direction: column;
      margin-top: 30px;
  }
 

  .team-sec .team-sec1 {
      width: 100%;
      margin-bottom: 20px;
  }

  .team-sec .team-sec2 {
      width: 100%;
      margin-top: 20px;
  }

  .team-sec1 img {
      width: 250px;
      height: 300px;
  }

  .dealor-profile {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 20px;
  }

}


@media screen and (min-width: 768px) and (max-width: 1200px) {

  
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .team-sec {
      flex-direction: column;
      margin-top: 30px;
  }
  #Marketing{
    height: 450px;
    width: 345px;
  }
  #Mapz{
    width: 100%;
  }
  .FiveMarlaPP p {
    font: 19px Montserrat;
    color: #000000;
    text-align: center;
  }

  .team-sec .team-sec1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
  }

  .team-sec .team-sec2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;
  }

  .team-sec1 img {
      width: 250px;
      height: 300px;
  }

  .dealor-profile, .dealor-profile1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0 0 0px;
      padding: 0 0 0 40px;
  }
}
.ViewProfile{
  text-decoration: none;
  color: black;
}
.ViewProfilee{
  text-decoration: none;
  color: black;
}
.card-img-overlay .card-title{

  height:5px;
  color:white;
  margin-bottom: 20px;
  text-align: center;
  
}
.aniText{
  display: none;
}
.card-img-overlay:hover .aniText{
  display: block;
  


}
#card1:hover .card-img-overlay{
  height: 100%;
  width: 100%;
  transition:4s;
    background-color:#5aa9b1;
}
 .card:hover .card-img-overlay{
  height: 100%;
  width: 100%;
  transition:.7s;
    background-color:#5aa9b1;


}
 .card:hover #card-img-overlay{
  height: 100%;
  width: 100%;
  transition:.7s;
    background-image: url("https://res.cloudinary.com/dfsabcjvk/image/upload/v1669214055/Dinproperties/mosque2_gdmh1x.jpg");
  background-size: 100% 100%;

}

 .card:hover .img1{
  height: 100%;
  width: 100%;
  transition:.7s;
  display: block;
    /* background-image: url("https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772480/Dinproperties/themepark_phy85j.jpg"); */
  background-size: 100% 100%;
  

}
#Card-img{
  width:100%;
  height: 250px;
}
.card-img-overlay{
  width:100%;
} 
.intro{
  background-color: #5aa9b1;
  width: 100%;
  position: absolute;
  bottom: -0px;
  overflow: hidden;
  height: 0px;
  color: white;
}
.card:hover .intro{
  height:100%;
  transition: 1s ease;
}
.Csr{
  background-color: #5aa9b1;
  width: 0px;
  position: absolute;
  bottom: -0px;
  overflow: hidden;
  height: 0px;
  color: white;
}
.card:hover .Csr{
  height:100%;
  width: 100%;
  transition: .5s ease;
}
#Ccard{
  width:370px;
}
#ContactUs{
  background-color: #F4EAD5;
  background-image: url(/public/images/bgcontact.jpg);
  background-size: cover;
}
#form_container {
  padding: 15px 15px;
  margin-top: 15px;
  background: rgba(255,255,255,0.90);
}
#ContCard:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}
.dropdown-menu li{
  position: relative;
}
.dropdown-menu .submenu{
  display: none;
  position: absolute;
  left: 100%;
  top:-7px;
}
.dropdown-menu li:hover .submenu{
  display: block;

}
#dropdown{
  display: none;
}
.dropdown:hover #dropdown{
  display: block;
}
.Link{
  text-decoration: none;
  
}
.Link:hover{
  color: white;
}
.navbar{
  border-bottom: 1px solid #dad8d8;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .map{
    width:335px;
  }
  #threecol{
    display: none;
  }
  .widget-top h4{
    font-size: 17px;
  }

  .textwidget p{
    font-size: 12px;
  }
  #fooot{
    display: block;
  }
  #props{
    margin-left: 50px;
  }
}