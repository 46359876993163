.bggg-image {
    width: 100%;
    height: 400px;
    background-image: url("./Images/bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 45px 0;
}

.bggg-image .bgg-text-sec {
    width: 50%;

}

.bgg-text-sec .bgg-text {
    width: 500px;
    color: white;
    text-align: justify;
    margin: 0 auto;
}

.bgg-text-sec p {
    font-size: 14px;
}

.bggg-image .logo-secc {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.logo-secc img {
    width: 500px;
    height: 250px;
    border-radius: 5px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .logo-secc img {
        width: 350px;
        height: 200px;
    }

    .bgg-text-sec .bgg-text {
        width: 350px;
    }

    .bgg-text-sec p {
        font-size: 12px;
    }

}


@media screen and (min-width: 480px) and (max-width: 768px) {
    .bggg-image {
        width: 100%;
        height: 670px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        background-size: 100% 670px;
    }

    .bggg-image .bgg-text-sec {
        width: 100%;
        color: white;
    }

    .bggg-image .logo-secc {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .logo-secc img {
        width: 450px;
        height: 200px;
    }

    .bgg-text-sec .bgg-text {
        width: 450px;
    }

}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .bggg-image {
        width: 100%;
        height: 730px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        background-size: 100% 730px;
    }

    .bggg-image .bgg-text-sec {
        width: 100%;
        color: white;
    }

    .bggg-image .logo-secc {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .logo-secc img {
        width: 90%;
    height: 190px;
    }

    .bgg-text-sec .bgg-text {
        width: 90%;
    }

}