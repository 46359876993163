


.waicon a{
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    left: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    z-index: 100;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}



.arrowcon {
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 20px;
    right: 15px;
    background-color: #f43127;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    z-index: 100;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.arrowcon:hover{
    color: #f43127;
    background-color: white;
    border: 1px solid #f43127;
}