head,
body {
    background-color: white;
    width: 100%;
    
}

.row {
    --bs-gutter-x: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    padding: 0;
    
    width: 320px;
    border: none;
}
/* .card-body {
    padding: 0;
} */

.hd {
    font-size: 50px;
    font-weight: 550
}

.card.hover,
.card:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, .2)
}

.img {
    margin-bottom: 35px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222)
}

.card-title {
    font-weight: 600
}

.card-subtitle {
    width: 240px;
    margin-left: auto;
    margin-right: auto;
}
.card-subtitle p {
    text-align: justify;
   
}


button.focus,
button:focus {
    outline: 0;
    box-shadow: none !important
}

.ft {
    margin-top: 25px
}

.chk {
    margin-bottom: 5px
}

.rck {
    margin-top: 20px;
    padding-bottom: 15px
}



@media screen and (min-width: 1024px) {
    .card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

}