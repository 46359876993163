
.main-divvv {
    width: 100%;
}

.social-div {
    width: 33.33333%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-div{
    width: 33.33333%;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.get-div{
    width: 33.33333%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.social-div a{
    padding:  0 10px;
}
.get-div a{
    padding:  0 10px;
}

.get-div button{
    background-color: #1A5257;
    color: white;
}


@media screen and (min-width: 1024px) and (max-width: 1180px) {
    

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    

}


@media screen and (min-width: 480px) and (max-width: 768px) {
    .social-div{
        display: none;
    }
    .logo-div, .get-div{
        width: 35%;
    }
    .get-div{
        width: 65%;
        justify-content: flex-end;
        padding-right: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .social-div{
        display: none;
    }
    .logo-div, .get-div{
        width: 50%;
    }

   
    .get-div{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 10px;
        align-items: flex-end;
    }
}