.blogpost {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  padding: 20px 0 10px 0;

}

.blogpost .Slider77-sec {
  height: 400px;
  padding: 0 10px;
}

.Slider77-sec .image-secc {
  width: 100%;
  overflow: hidden;


}

.Slider77-sec .text-sec {
  padding: 10px 0 5px 10px;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 1px, rgba(0, 0, 0, 0) 0px 4px 2px, rgba(0, 0, 0, 0.50) 0px 3px 4px, rgba(0, 0, 0, 0.09) 0px 0px 0px, rgba(0, 0, 0, 0.09) 0px 0px 10px;
}

.text-sec h3 {
  font-size: 18px;
  color: brown;
}

.Slider77-sec .text-last-sce  {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.text-last-sce div{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.text-last-sce p{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.image-secc .cont-imaaag {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .5s ease-out 0s;
  -webkit-transition: all .5s ease-out 0s;
}

.image-secc .cont-imaaag:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transition: all .5s ease-out 0s;
  -webkit-transition: all .5s ease-out 0s;

}


/* 
@media screen and (min-width: 800px) and (max-width: 1280px) {
  .blogpost .Slider77-sec {
    height: 280px;
  }

  .Slider77-sec .image-secc {
    height: 280px;
  }

  .image-secc .cont-imaaag {
    height: 280px;
  }

}

@media screen and (min-width: 480px) and (max-width: 800px) {
  .blogpost .Slider77-sec {
    height: 250px;
  }

  .Slider77-sec .image-secc {
    height: 250px;
  }

  .image-secc .cont-imaaag {
    height: 250px;
  }

}

@media screen and (min-width: 300px) and (max-width: 480px) {

  .blogpost .Slider77-sec {
    height: 250px;
  }

  .Slider77-sec .image-secc {
    height: 250px;
  }

  .image-secc .cont-imaaag {
    height: 250px;
  }
} */