.bg-image-sec {
    width: 100%;
    height: 400px;
    background-image: url(./Images/layoutbg.jpg);
    background-size: 100% 400px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.bg-image-sec .discripation {
    width: 50%;
}

.discripation .text-divv {
    width: 500px;
    color: white;
    text-align: justify;
    margin: 0 auto;
}

.bg-image-sec .video-sec {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layoutplan {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layoutplan img {
    width: 100%;
    max-width: 80%; 
    height: 350px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.layoutplan img:hover {
    opacity: 0.7;
}
.modal {
display: none; 
position: fixed; 
z-index: 1; 
padding-top: 100px; 
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.9); 
}

/* Modal Content (image) */
.modal-content {
margin: auto;
display: block;
width: 100%;
max-width: 800px;
height: 500px;
}

/* Caption of Modal Image */
#caption {
margin: auto;
display: block;
width: 80%;
max-width: 700px;
text-align: center;
color: #ccc;
padding: 10px 0;
height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
-webkit-animation-name: zoom;
-webkit-animation-duration: 0.6s;
animation-name: zoom;
animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
from {-webkit-transform:scale(0)} 
to {-webkit-transform:scale(1)}
}

@keyframes zoom {
from {transform:scale(0)} 
to {transform:scale(1)}
}

/* The Close Button */
.close {
position: absolute;
top: 15px;
right: 35px;
color: #f1f1f1;
font-size: 20px;
font-weight: bold;
transition: 0.3s;
}

.close:hover,
.close:focus {
color: #bbb;
text-decoration: none;
cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
.modal-content {
  width: 100%;
}
}


.amenities {
    margin: 20px 0;
}

.amenities .main-image-sec {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.main-image-sec .first-sec {
    width: 49%;
    margin-right: 1%;
}

.first-sec img {
    width: 100%;
    max-width: 700px;
    height: 450px;
    border-radius: 10px;
    cursor: pointer;
}


.main-image-sec .second-sec {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.second-sec .first-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.first-row .swimi1 {
    width: 49%;
    margin-right: 1%;

}

.first-row .swimi2 {
    width: 49%;
    margin-left: 1%;
}

.swimi1 img,
.swimi2 img,
.swimi3 img,
.swimi4 img {
    width: 100%;
    max-width: 700px;
    max-height: 600px;
    min-height: 220px;
    border-radius: 10px;
    cursor: pointer;
}

.second-sec .second-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;

}

.second-row .swimi3 {
    width: 50%;
    margin-right: 1%;
}

.second-row .swimi4 {
    width: 50%;
    margin-left: 1%;
}

.iframe-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.video-responsive {
    width: 52%;
    height: 397px;
}

@media screen and (min-width: 1024px) and (max-width: 1180px) {
    .video-responsive {
        width: 60%;
        height: 350px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .video-responsive {
        width: 60%;
        height: 330px;
    }

    .first-sec img {
        width: 100%;
        height: 350px;
        border-radius: 10px;
    }

    .swimi1 img,
    .swimi2 img,
    .swimi3 img,
    .swimi4 img {
        width: 100%;
        height: 175px;
        border-radius: 10px;
    }


    .bg-image-sec {
        height: 400px;
    }

    .bg-image-sec .discripation {
        color: white;
        text-align: justify;
        margin: 0 0 0 15px;
        padding: 10px 10px;
        border-radius: 5px;
    }
    .discripation .text-divv {
        width: 100%;
    }

    .layoutplan img {
        width: 80%;
        height: 350px;
    }

}


@media screen and (min-width: 480px) and (max-width: 768px) {

    .bg-image-sec {
        height: 600px;
        background-size: 100% 600px;
        flex-direction: column;
    }
    .bg-image-sec .discripation {
        width: 90%;
    }
    .discripation .text-divv {
        width: 100%;
        color: white;
        text-align: justify;
        margin: 0 auto;
    }
    .layoutplan {
        width: 100%;
        
    }
    .layoutplan img {
        width: 90%;
        height: 300px;
    }


    .amenities .main-image-sec {
        display: flex;
        flex-direction: column;
    }
    .main-image-sec .first-sec {
        width: 100%;
        margin: 0 0 10px 0 ;
    }
    .main-image-sec .second-sec {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .first-sec img {
        width: 100%;
        height: 350px;
        border-radius: 10px;
    }

    .swimi1 img,
    .swimi2 img,
    .swimi3 img,
    .swimi4 img {
        width: 100%;
        height: 175px;
        border-radius: 10px;
    }

    .bg-image-sec .video-sec {
        width: 100%;
    }

    .video-responsive {
        width: 430px;
        height: 225px;
    }

}

@media screen and (min-width: 320px) and (max-width: 480px) {

    .bg-image-sec {
        height: 600px;
        background-size: 100% 600px;
        flex-direction: column;
    }
    .discripation .text-divv {
        width: 100%;
        color: white;
        text-align: justify;
        margin: 0 auto;
    }
    .layoutplan {
        width: 100%;
        
    }
    .layoutplan img {
        width: 90%;
        height: 300px;
    }
    .amenities .main-image-sec {
        display: flex;
        flex-direction: column;
    }
    .main-image-sec .first-sec {
        width: 100%;
        margin: 0 0 10px 0 ;
    }
    .main-image-sec .second-sec {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .first-sec img {
        width: 100%;
        height: 250px;
        border-radius: 10px;
    }

    .swimi1 img,
    .swimi2 img,
    .swimi3 img,
    .swimi4 img {
        width: 100%;
        height: 125px;
        border-radius: 10px;
    }
    

    .bg-image-sec .discripation {
        width: 90%;
        color: white;
        text-align: justify;
        margin: 0 0 20px 0;
        padding: 0;
        border-radius: 5px;
    }

    .bg-image-sec .video-sec {
        width: 100%;
    }

    .video-responsive {
        width: 300px;
        height: 150px;
    }

}